/* Navbar */
.navbar{
    
   /* height: 172px;*/
}
.bghome{
    background: linear-gradient(to top, rgba(255,0,0,0), rgba(0,0,0,0.5));
}
.bgpages{
    background: #C1D837;
}
.expandido{
    background: #C1D837 !important;
}
.collapse.navbar-collapse{
    flex-grow: 0;
}
.dropdown-big{
    position: unset !important;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #0d6efd;
}
#dropdownMenuButton::after,
#dropdownMenuButton2::after,
#dropdownMenuButton3::after{
    display: none;
}

/* Dropdown Menu */
.navbar .dropdown-menu{
    border:none;
    color: #fff;
    filter: drop-shadow(3px 3px 8px rgba(00, 00, 000, 0.1));
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background-color:rgba(255, 255, 255, 0.6);
    margin-top: 8px !important;
    padding: 2rem;
}
.isFirefox .navbar .dropdown-menu{
    background-color:rgba(255, 255, 255,1);
}
.navbar .freedrop{
    border:none;
    color: #fff;
    filter: drop-shadow(3px 3px 8px rgba(00, 00, 000, 0.1));
    backdrop-filter: blur(30px);
    background-color:rgba(255, 255, 255, 0.6);
    margin-top: 41px !important;
    align-content: left;
}
.navbar-toggler,
.navbar-toggler-icon{
    color: white !important;;
    border-color:white !important;
}
.navbar-toggler.collapsed{
    border-width: 1px !important;
}
@media (max-width: 992px) {
    .navbar .freedrop{
        margin-top: 1px!important;
        align-content: left;
    }

    .nav-link.dropdown-toggle.btn {
        text-align: left;
    }
    .nav-link.dropdown-toggle.btn{
        display: flex;
        justify-content: space-between;
    }
}
.isFirefox .navbar .freedrop{
    background-color:rgba(255, 255, 255,1);
}
.navbar .dropdown-item:hover{
    text-decoration: underline 2px ;
    background-color:#e9ecef !important;
    background:#e9ecef !important;
}

.navbar .btn{
    border: none;
    border-bottom: 2px solid transparent;
    background-color: transparent;
    border-radius: 0;
   /* margin-right: 4rem;*/
    font-size: 20px;
    font-family: 'Andada Pro', serif;    
    color: #fff !important;
}
.navbar .btn:hover{
    background-color: transparent;
    border-bottom: 2px solid white;
    border-radius: 0;
}
.fa-chevron-down{
  transform: rotate(0deg);
  transition: transform 0.1s linear;
}

.navbar .btn:focus .fa-chevron-down{
  transform: rotate(180deg);
  transition: transform 0.1s linear;
}
/* Menu Hamburguer */
.dropdown-menu-long{
   /* height: 505px;*/
}
.dropdown-menu-long li{
    display:block;
  /*  max-width: 200px;*/
}
.dropdown-menu-long ul{
    max-width: 'Noto Sans Display', sans-serif;
    font-weight: 400;
    font-size: 16px;
    padding: 0;
    margin-bottom:20px;
}
.dropdown-menu-long a{
    font-family: 'Noto Sans Display', sans-serif;
    white-space:inherit;
    font-size: 16px;
    padding-left:5px;
    padding-right:5px;
    color:#000000
}
.dropdown-menu-long a:hover{
    
    /*text-shadow: 1px 1px 0px #cecece;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;*/
    color:black;
}
.dropdown-menu-long h3{
    font-family: 'Noto Sans Display', sans-serif;
    font-size: 22px;
    font-weight: normal;
    color: #3E3E3E;
    margin: 5px;
    margin-bottom:1rem;
}
.dropdown-menu-long h3 a{
    font-family: 'Noto Sans Display', sans-serif;
    font-size: 22px;
    font-weight: normal;
    color: #3E3E3E;
    text-decoration:none;
    margin-left:-5px;
}
.dropdown-menu-long h3 a:hover{
    text-decoration:underline;
}


.aux-fix-direction{
    flex-direction:column;
} 
@media (min-width: 992px) {
.aux-fix-direction{
    flex-direction:row;
}    
.dropdown-menu-long{
    margin-left: 0%;
    }
.nav-item{
   margin-left:4rem; 
}    
}


@media (min-width: 1200px) {
.dropdown-menu-long{
    /*margin-left: 7.65% !important;
    margin-right:7.65% !important;*/
    }
}
/* Search Box */

.box{
    position: absolute;
    bottom: 5%;
    right: 7.65%;
    transform: translate(-50%,-50%);
}
.input {
    padding: 10px;
    width: 80px;
    height: 80px;
    background: none;
    border: none;
    font-family: 'Noto Sans Display', sans-serif;
    font-size: 20px;
    color: #58595B;
    outline: none;
    transition: .5s;
}

.box:hover input{
    width: 24rem;
    backdrop-filter: blur(30px);
    background-color:rgba(255, 255, 255, 0.6);
    margin-left: 30%;
}
.box i{
    position: absolute;
    top: 50%;
    right: 18%;
    transform: translate(-50%,-50%);
    font-size: 33px;
    color: white;
    transition: .2s;
}
.box:hover i{
    opacity: 0;
}

.box ::placeholder{
    opacity: 0;
}
.box :hover::placeholder{
    opacity: 1;
}
/* Temporario/Estetico */
.bi-search{
    font-size: 22px;
    color: white;
}
.dropdown-item.active,
 .dropdown-item:active,
 .dropdown-item.active a ,
 .dropdown-item:active a {
   
    background-color: transparent!important;
    color:black!important;
}
/*
.navbar li.nav-item.dropdown{
    border-bottom:1px solid white;
}*/


/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
    .navbar.navbar-expand-lg.navbar-light.bghome.expandido,
    .navbar.navbar-expand-lg.navbar-light.bghome{
        background-color: transparent !important;
        padding-bottom:0rem !important;
        margin-bottom: -11rem !important;

    }
}

section.content{
    margin-bottom:8rem;
}


/**********************/
.nav-item .pesquisa{
    border-bottom:none
} 


.container_pesquisa {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
   /* width: 300px;
    height: 100px;*/
}
.container_pesquisa   .search {
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 65px;
    height: 65px;
    background: #C1D837;
    border-radius: 50%;
    transition: all 1s;
    z-index: 4;
   /* box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.4);*/
    /*/ box-shadow: 0 0 25px 0 crimson;*/
  }
  
/*  
  .container_pesquisa   .search.focus i,
  .container_pesquisa   .search:hover i{
      font-weight: bold;
      font-size:30px!important;
  } 
*/
  .bghome .container_pesquisa   .search {
    
    background: transparent;
    border-radius: 50%;
    transition: all 1s;
    z-index: 4;
    /*box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.4);*/
    /*/ box-shadow: 0 0 25px 0 crimson;*/
  }
.container_pesquisa   .search:hover {
        cursor: pointer;
        overflow-y:hidden;
        
      }
    
    
.container_pesquisa   input {
        font-family:'Noto Sans Display', sans-serif;
        position: absolute;
       /* margin: auto;*/
        top: 2px;
        right: 0;
        bottom: 0;
        left: 0;
        width: 40px;
        height: 40px;
        outline: none;
        border: none;
       /* // border-bottom: 1px solid rgba(255, 255, 255, 0.2);*/
        background: white;
        /*color: #C1D837;*/
        padding: 0 80px 0 20px;
        border-radius: 15px;
        box-shadow: 0 0 25px 0 #C1D837,
                    0 20px 25px 0 rgba(0, 0, 0, 0.2);
        /*// box-shadow: inset 0 0 25px 0 rgba(0, 0, 0, 0.5);*/
        transition: all 1s;
        opacity: 0;
        z-index: 5;
        letter-spacing: 0.1em;
        font-size:16px;
      }
      
.bghome .container_pesquisa   input {
       
        background: white;
        color:#333333;
        box-shadow: none;
      }
.container_pesquisa   input:hover {
        cursor: pointer;
}
.container_pesquisa   input.animateIn {
        width: 450px;
        left: -450px;
        opacity: 1;
        cursor: text;
        margin-right:0px;
        
}
/*      
.bghome .container_pesquisa   input:focus {
        width: 450px;
        left: -450px;
        opacity: 1;
        cursor: text;
        margin-right:0px;
        
}*/
      
      @media (min-width: 1px) {
        #pesquisa1{
            display:none;
        }
        #pesquisa2{
            display:flex;
        }
        #Menu1{display:none}
        #Menu2{display:inline}
    }
      @media (min-width: 991px) {
        #pesquisa1{
            display:none;
        }
        #pesquisa2{
            display:block;
        }

        #Menu1{display:inline}
        #Menu2{display:none}
    }
    @media (min-width: 1000px) {
        #pesquisa1{
            display:block;
        }
        #pesquisa2{
            display:none;
        }

        #Menu1{display:inline}
        #Menu2{display:none}
    }
    
    
    .container_pesquisasmall{
        position:'relative';
        display:"flex";
        justify-content: "space-between";
        padding-bottom:1px;
    }
.container_pesquisasmall button.btn{
      text-align: right;
      padding-right:0;   

}

.dropdown-menu.show a{
    /*padding-left:0!important;padding-right:0!important;*/
    padding-left:0.5rem;
    padding-right:0.2rem;
}

